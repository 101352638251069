<template>
  <div class="all">
    <div class="content w1400">
      <span>同鑫独家精准分析，助你更高投资成就</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImgNav',
}
</script>

<style scoped>
.all {
  width: 100%;
  height: 180px;
  background-color: #3D60BB;
}

.content {
  height: 100%;
  line-height: 180px;
  text-align: center;
  background: url('~assets/img/industryInfo/tabbg.png');
}

.content span {
  font-size: 39px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
</style>
