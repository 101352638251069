// 行情分析模块
export default {
  namespaced: true, // 命名空间
  state: () => ({
    filters: {
      cate: null,
      type: 0,
      cateList: [],
      typeList: []
    }
  }),
  mutations: {
    setCate(state, payload) {
      state.filters.cate = payload
    },
    setType(state, payload) {
      state.filters.type = payload
    },
    setCateList(state, payload) {
      state.filters.cateList = payload
    },
    setTypeList(state, payload) {
      state.filters.typeList = payload
    },
  },
  actions: {},
  getters: {}
}