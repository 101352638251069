<template>
  <!-- <div class="all" :style="style">
    <slot>
      <h1 class="title">天津小鹏铝业</h1>
      <p>
        主营范围：打造世界轻量化铝型材产品，产品广泛用与建筑、轨道交通、机械、化工、电子电器，船舶、航空等多份领域
      </p>
    </slot>
  </div> -->
</template>

<script>
export default {
  name: 'Advertisement',
  props: {
    width: {
      type: String | Number,
      default: 1400,
    },
    height: {
      type: String | Number,
      default: 180,
    },
  },
  data() {
    return {
      style: {
        width: this.width + 'px',
        height: this.height + 'px',
      },
    }
  },
}
</script>

<style scoped>
.all {
  text-align: center;
  padding: 25px;
  background-color: skyblue;
  color: #ffffff;
}
.title {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
}
p {
  line-height: 25px;
  font-size: 14px;
  margin-top: 10px;
}
</style>
