// 供求市场模块
export default {
  namespaced: true, // 命名空间
  state: () => ({
    // 筛选条件
    filterCondition: {
      cate: 1, // 类目
      area: null, // 地区
      source: null, // 来源
      delivery: null, // 提货
      type: null, // 类别
    },
    cateList: [], // 类目列表
    areaList: [], // 地区列表
    typeList: [], // 类别列表

    productType: 0, // 0货物供应 1货物采购
    productList: [], // 当前页的数据
    productListTotal: 0, // 总数

  }),
  mutations: {
    // 类目
    setCate(state, payload) {
      state.filterCondition.cate = payload
    },
    // 地区
    setArea(state, payload) {
      state.filterCondition.area = payload
    },
    // 来源
    setSource(state, payload) {
      state.filterCondition.source = payload
    },
    // 提货
    setDelivery(state, payload) {
      state.filterCondition.delivery = payload
    },
    // 类别
    setType(state, payload) {
      state.filterCondition.type = payload
    },
    // 类目列表
    setCateList(state, payload) {
      state.cateList = payload
    },
    // 地区列表
    setAreaList(state, payload) {
      state.areaList = payload
    },
    // 类别列表
    setTypeList(state, payload) {
      state.typeList = payload
    },

    // 货物类型
    setProductType(state, payload) {
      state.productType = payload
    },
    // 货物列表
    setProductList(state, payload) {
      state.productList = payload
    },
    // 货物列表数量
    setProductListTotal(state, payload) {
      state.productListTotal = payload
    },
  },
  actions: {},
  getters: {}
}