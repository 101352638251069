<template>
  <div class="all">
    <div class="tab w1400">
      <div v-for="(item, i) in list" class="tabDiv">
        <span
          @click="itemClick(item.path)"
          :class="{ blue: i == list.length - 1 }"
          >{{ item.name }}</span
        >
        <Icon v-if="i != list.length - 1" type="ios-arrow-forward" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetailNavBar',
  props: {
    title: String,
  },
  mounted() {
    this.$nextTick(() => {
      this.getParentPath()
    })
  },
  data() {
    return {
      list: [],
    }
  },
  methods: {
    getParentPath() {
      this.list = [{ name: '首页', path: '/home' }]
      let parentName = this.$route.meta.title.replace(/详情/g, '')
      let { routes } = this.$router.options
      let parentRoute = routes.find((e) => e.meta && e.meta.title == parentName)
      if (parentRoute) {
        let children = {
          name: this.title,
          path: this.$route.path,
        }
        let parent = {
          name: parentName,
          path: parentRoute.path,
        }
        this.list.push(parent, children)
      }
    },
    itemClick(path) {
      if (this.$route.path == path) return
      this.$router.push({ path })
    },
  },
  watch: {
    title(v) {
      if (v && v.length > 0) {
        this.getParentPath()
      }
    },
  },
}
</script>

<style scoped>
.all {
  width: 100%;
  height: 40px;
  background-color: #e6ebf5;
}

.tab {
  height: 100%;
  display: flex;
  align-items: center;
  color: #000;
  font-size: 14px;
}

.tabDiv {
  margin-right: 10px;
}
.tabDiv span {
  margin-right: 10px;
  cursor: pointer;
}

.blue {
  color: #25449a;
}
</style>
