<template>
  <div class="logobar">
    <div class="footbac">
      <div class="w1400 foot_top clearfix">
        <ul class="goods">
          <li>产品服务</li>
          <li><router-link :to="'/marketprice'">行情报价</router-link></li>
          <li><router-link :to="'/bidAuction'">招标拍卖</router-link></li>
          <!-- <li><router-link :to="'/supplyMarket'">供求市场</router-link></li> -->
          <li><router-link :to="'/industryInfo'">行业资讯</router-link></li>
        </ul>
        <ul class="our">
          <li>关于我们</li>
          <li><router-link :to="'/aboutUs'">公司简介</router-link></li>
          <li><router-link :to="'/industryInfo'">新闻资讯</router-link></li>
        </ul>
        <div class="gzhimg">
          <img src="~assets/img/public/QRcode_wx.jpg" />
          <p>微信公众号</p>
        </div>
        <div class="appimg">
          <img src="~assets/img/public/QRcode_app.png" />
          <p>APP扫码下载</p>
        </div>
      </div>
      <p class="pstyle1">
        Copyright © 1999-2011 Shanghai Tongxin Message Technology Consu
      </p>
      <p class="pstyle2">
        版权所有Copyright ©上海同鑫信息技术咨询有限公司
        版权申明：未经授权许可，禁止以任何方式链接、引用本网站内容
      </p>
    </div>
    <div class="botm">
      <div v-for="item in links" @click="goHref(item.href)">
        <img :src="item.img" :alt="item.alt || item.text">
        <span>{{ item.text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LogoBar',
  data() {
    return {
      links: [
        {
          img: require('assets/img/gsj.png'),
          href: 'http://www.sgs.gov.cn/lz/licenseLink.do?method=licenceView&amp;entyId=20120315103549600',
          text: '上海工商',
        },
        {
          img: require('assets/img/ba.png'),
          href: 'http://www.beian.gov.cn',
          alt: '沪公网备',
          text: '沪公网备310100104029',
        },
        {
          img: require('assets/img/gy.png'),
          href: 'http://beian.miit.gov.cn',
          alt: '上海工商',
          text: '沪ICP备14041613号-1',
        },
      ],
    }
  },
  methods: {
    goHref(href) {
      window.open(href, '_blank')
    }
  }
}
</script>

<style scoped>
.logobar {
  width: 100%;
  height: 400px;
  background: #091c46;
  /*position: fixed;*/
  /*bottom: 0;*/
}
.logobar .footbac {
  width: 100%;
  height: 320px;
  background: url('~assets/img/footerbac.png');
}
.foot_top {
  padding-top: 45px;
}
.goods {
  float: left;
  margin-right: 150px;
}
.our {
  float: left;
}
.goods,
.our {
  font-size: 16px;
  color: #fff;
}
.goods li,
.our li {
  line-height: 40px;
}
.goods li a,
.our li a {
  color: #77839c;
}
.appimg,
.gzhimg {
  width: 100px;
  float: right;
  text-align: center;
  line-height: 40px;
  color: #77839c;
  font-size: 16px;
  margin-left: 45px;
}
.appimg img,
.gzhimg img {
  width: 100px;
  height: 100px;
}
.footbac p {
  text-align: center;
  color: #fff;
}
.footbac p.pstyle1 {
  font-size: 12px;
  margin-top: 10px;
  line-height: 30px;
}
.footbac p.pstyle2 {
  font-size: 13px;
}
.botm {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 500px;
  height: 80px;
  margin: 0 auto;
}
.botm img{
  width: 20px;
  height: 20px;
}
.botm div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 25px;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
}
.botm div img {
  margin-right: 5px;

}
.foot_top a:hover {
  text-decoration: underline;
  color: #25449a;
}
</style>
