<template>
  <div id="app">
    <component :is="isComponent" />
    <router-view v-wechat-title="$route.meta.title" />
    <logo-bar />
    <ContrantQQ />
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  computed: {
    isComponent() {
      let list = ['login', 'register']
      let isLogin = list.includes(this.$route.name)
      if (isLogin) {
        return 'NavHeadlogin'
      } else {
        return 'NavHead'
      }
    },
  },
}
</script>

<style>
@import 'assets/css/base.css';
</style>
