export default {
  pushReadMail(context, mail) {
    let userName = localStorage.getItem('userName')
    let userMail = context.state.userMail
    let res = userMail.find(e => e.userName == userName)
    if (!res) {
      let data = {
        userName,
        mail: [mail]
      }
      context.commit('addNewUserMail', data)
    } else {
      // 当前用户已读邮件
      let nowUserMails = res.mail.map(e => e.id)
      if (!nowUserMails.includes(mail.id)) {
        context.commit('addHadUserMail', mail)
      }
    }
  },
  removeReadMail(context) {
    let userName = localStorage.getItem('userName')
    let userMail = context.state.userMail.find(
      (e) => e.userName == userName
    )
    if (userMail) {
      let now = +new Date()
      userMail.mail.forEach(item => {
        let itemDate = +new Date(item.date)
        let isNowDay = parseInt((now - itemDate) / 1000 / 60 / 60) < 24 * 7
        if (!isNowDay) {
          context.commit('removeHadUserMail', item.id)
        }
      });
    }

  }
}