import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/index'
import {Modal, Message } from 'iview'    //引入ivew库
const Login = () => import('views/login/Login')
const Register = () => import('views/login/Register')
const Home = () => import('views/home/Home')
const Marketprice = () => import('views/home/Marketprice')
const Marketdetail = () => import('views/home/Marketdetail')
const Analysis = () => import('views/home/Analysis')
const AnalysisDel = () => import('views/home/AnalysisDel')
const ScrapPrice = () => import('views/home/ScrapPrice')
const Scrapdetail = () => import('views/home/Scrapdetail')
const Mappage = () => import('views/home/Mappage')
const PersonalCenter = () => import('views/personalCenter/PersonalCenter') // 个人中心
const SupplyMarket = () => import('views/supplyMarket/SupplyMarket') // 供求市场
const SupplyMarketDetail = () => import('views/supplyMarket/SupplyMarketDetail') // 供求市场详情
const IndustryInfo = () => import('views/industryInfo/IndustryInfo') // 行业资讯
const IndustryDetail = () => import('views/industryInfo/IndustryDetail') // 行业资讯详情
const IndustryList = () => import('views/industryInfo/IndustryList') // 行业资讯列表
const BidAuction = () => import('views/bidAuction/BidAuction') // 招标拍卖列表
const BidAuctionDel = () => import('views/bidAuction/BidAuctionDel') // 招标拍卖详情
const UsersPage = () => import('views/usersPage/UsersPage') // 同鑫黄页
const UsersPageDel = () => import('views/usersPage/UsersPageDel') // 同鑫黄页详情
const AppDown = () => import('views/appDown/AppDown') // App下载
const AboutUs = () => import('views/aboutUs/AboutUs') // 关于我们

Vue.use(Router)
const routes = [
  {
    path: '',
    redirect: '/home'
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: '登录',
    }
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: {
      title: '注册'
    }
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      title: '首页',
      noLogin: true,
    }
  },
  {
    path: '/marketprice',
    name: 'marketprice',
    component: Marketprice,
    meta: {
      title: '行情价格',
      noLogin: true,
    }
  },
  {
    path: '/marketdetail',
    name: 'marketdetail',
    component: Marketdetail,
    meta: {
      title: '行情价格详情',
    }
  },
  {
    path: '/scrapprice',
    name: 'scrapprice',
    component: ScrapPrice,
    meta: {
      title: '废钢调价',
    }
  },
  {
    path: '/scrapdetail',
    name: 'scrapdetail',
    component: Scrapdetail,
    meta: {
      title: '废钢调价详情',
    }
  },
  {
    path: '/mappage',
    name: 'mappage',
    component: Mappage,
    meta: {
      title: '废钢地图',
      noLogin: true,
    }
  },
  {
    path: '/analysis',
    name: 'analysis',
    component: Analysis,
    meta: {
      title: '分析指导',
    }
  },
  {
    path: '/analysisDel',
    name: 'analysisDel',
    component: AnalysisDel,
    meta: {
      title: '分析指导详情',
    }
  },
  {
    path: '/personalCenter',
    name: 'personalCenter',
    component: PersonalCenter,
    meta: {
      title: '个人中心'
    }
  },
  {
    path: '/supplyMarket',
    name: 'SupplyMarket',
    component: SupplyMarket,
    meta: {
      title: '供求市场',
      noLogin: true,
    }
  },
  {
    path: '/supplyMarket/detail',
    name: 'SupplyMarketDetail',
    component: SupplyMarketDetail,
    meta: {
      title: '供求市场详情'
    },
  },
  {
    path: '/industryInfo',
    name: 'IndustryInfo',
    component: IndustryInfo,
    meta: {
      title: '行业资讯',
      noLogin: true
    }
  },
  {
    path: '/industry/detail',
    name: 'IndustryDetail',
    component: IndustryDetail,
    meta: {
      title: '行业资讯详情'
    }
  },
  {
    path: '/industry/list',
    name: 'IndustryList',
    component: IndustryList,
    meta: {
      title: '行业资讯列表',
      noLogin: true
    }
  },
  {
    path: '/bidAuction',
    name: 'BidAuction',
    component: BidAuction,
    meta: {
      title: '招标拍卖',
    }
  },
  {
    path: '/bidAuction/detail',
    name: 'BidAuctionDel',
    component: BidAuctionDel,
    meta: {
      title: '招标拍卖详情',
    }
  },
  {
    path: '/usersPage',
    name: 'UsersPage',
    component: UsersPage,
    meta: {
      title: '同鑫黄页',
    }
  },
  {
    path: '/usersPage/detail',
    name: 'UsersPageDel',
    component: UsersPageDel,
    meta: {
      title: '同鑫黄页详情',
    }
  },
  {
    path: '/appDown',
    name: 'AppDown',
    component: AppDown,
    meta: {
      title: 'App下载',
      noLogin: true
    }
  },
  {
    path: '/aboutUs',
    name: 'AboutUs',
    component: AboutUs,
    meta: {
      title: '关于我们',
      noLogin: true
    }
  },
]

const router = new Router({
  routes,
  mode: 'hash' //mode: "history"
})

// 判断是否登录
router.beforeEach((to, from, next) => {
  let noLogin = to.matched[0].meta.noLogin
  let token = store.state.token
  let isTo = ['login', 'register']
  if ((!token || token.length == 0) && !isTo.includes(to.name) && !noLogin) {
    Message.warning('请先登录！')
    next({ path: '/login' })
  } else {
    if(to.name=="scrapprice"){
      if(localStorage.getItem('setVip')==1){
        next()
      }else{
        Modal.warning({
          title: '提示',
          content:
            '<p>此服务为VIP专属，开通服务请致电</p><p>13329574939</p>',
        })
      }
    }else if(to.name=="analysis"|| to.name=="analysisDel"){
      if(localStorage.getItem('fxVip')&&localStorage.getItem('fxVip')>0){
        next()
      }else{
        Modal.warning({
          title: '提示',
          content:
            '<p>此服务为VIP专属，开通服务请致电</p><p>13329574939</p>',
        })
      }
    }else{
      next()
    }
  }
})

// 跳转后返回到页面顶部
router.afterEach((to, from, next) => {
  window, scrollTo(0, 0)
})

export default router
