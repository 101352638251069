// 个人中心模块
export default {
  namespaced: true, // 命名空间
  state: () => ({
    authData: {}, // 资料认证
    infoData: {}, // 我的资料
    isRefresh: 0, // 是否重新获取接口 0默认不获取 1获取资料认证 2获取我的资料

    sideBarIndex: 0, // 侧边栏状态 0我的账户 1我的信箱
    mailType: 0, // 邮箱类型 0行情订阅 1供求订阅

    quotationMailDot: 0, // 行情订阅 新信箱数量
    supplyMailDot: 0, // 供求订阅 新信箱数量
    totalMailDot: 0, // 所有的 新信箱数量
  }),
  mutations: {
    // 保存 资料认证 信息
    setAuthData(state, payload) {
      state.authData = payload
    },
    // 保存 我的资料 信息
    setInfoData(state, payload) {
      state.infoData = payload
    },
    setIsRefresh(state, payload) {
      state.isRefresh = payload
    },
    setSideBarIndex(state, payload) {
      state.sideBarIndex = payload
    },
    setMailType(state, payload) {
      state.mailType = payload
    },
    setSideBarIndex(state, payload) {
      state.sideBarIndex = payload
    },
    setQuotationMailDot(state, payload) {
      state.quotationMailDot = payload
    },
    setSupplyMailDot(state, payload) {
      state.supplyMailDot = payload
    },
    setTotalMailDot(state, payload) {
      state.totalMailDot = payload
    },
    // 点了新的信息，就减一
    subMailDot(state, payload) {
      // 0行情 1供求
      if (payload == 0) {
        state.quotationMailDot--
      } else {
        state.supplyMailDot--
      }
      state.totalMailDot--
    }
  },
  actions: {},
  getters: {}
}