import axios from 'axios'
import config from '../common/config'

function request(config) {
  const instance = axios.create({
    baseURL: '',
    timeout: 5000
  })
  return instance(config)
}

// 认证上传图片
export function authupload(data) {
  return request({
    url: config.upLoadImgUrl.authupload,
    method: 'post',
    data: data
  })
}

// 黄页上传图片
export function yellowUpload(data) {
  return request({
    url: config.upLoadImgUrl.yellowUpload,
    method: 'post',
    data: data
  })
}