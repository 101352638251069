// 招标拍卖模块
export default {
  namespaced: true, // 命名空间
  state: () => ({
    filters: {
      cate: 2,
      city: 0,
      cateList: [],
      cityList: []
    }
  }),
  mutations: {
    setCate(state, payload) {
      state.filters.cate = payload
    },
    setCity(state, payload) {
      state.filters.city = payload
    },
    setCateList(state, payload) {
      state.filters.cateList = payload
    },
    setCityList(state, payload) {
      state.filters.cityList = payload
    },
  },
  actions: {},
  getters: {}
}