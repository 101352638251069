import { request } from './request'

// 获取省市
export function getProvince() {
  return request({
    url: '/txapi/getProvince',
    method: 'get',
  })
}

// 获取QQ号在线状态
export function getQQLine(QQ) {
  return request({
    url: `/qqapi/getonline?Type=1&${QQ}:`,
  })
}
