<template>
  <div @click="contrant" class="contantQQImg">
    <img
      border="0"
      src="~assets/img/home/contantQQ.gif"
      alt="点击这里给我发消息"
      title="点击这里给我发消息"
    />
  </div>
</template>

<script>
import { getQQLine } from 'network/public'

export default {
  name: 'ContrantQQ',
  data() {
    return {
      href: '',
    }
  },
  methods: {
    async contrant() {
      let lineQQ = ''
      let isLine = null
      let { contrantQQ } = this.$config
      for (const QQ of contrantQQ) {
         isLine = await this.getOnLine(QQ)
         if(isLine == 1) {
           lineQQ = QQ
           break
         }
         lineQQ = QQ
      }
      window.open(`http://wpa.qq.com/msgrd?v=3&uin=${lineQQ}&site=qq&menu=yes`, '_blank')
    },
    getOnLine(QQ) {
      return new Promise((resolve) => {
        getQQLine(QQ).then((res) => {
          let online = []
          eval(res)
          resolve(online[0])
        })
      })
    },
  },
}
</script>

<style scoped>
.contantQQImg {
  position: fixed;
  top: 75%;
  right: 20px;
  cursor: pointer;
}
.contantQQImg:hover {
  transform: scale(1.2);
}
</style>
