import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import config from 'common/config'
import handle from 'common/handle'
import * as echarts from 'echarts'
import plugins from 'components/index'
import iView from 'iview'
import 'iview/dist/styles/iview.css'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import scroll from 'vue-seamless-scroll'
import axios from 'axios'
import VueWechatTile from 'vue-wechat-title'

Vue.prototype.$axios = axios;
Vue.prototype.$echarts = echarts;
Vue.prototype.$config = config // 全局注册配置
Vue.prototype.$handle = handle // 全局处理函数

Vue.use(plugins) // 注册全局组件
Vue.use(iView)
Vue.use(Viewer)
Vue.use(scroll)
Vue.use(VueWechatTile)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
