<template>
  <div>
    <viewer :images="imgList" class="viewer" ref="viewer">
      <template scope="scope">
        <div class="imgDiv" v-for="(img, i) in scope.images" :key="img.pic">
          <img :src="imgUrl + img" class="img" />
          <div class="delCls">
            <h2 @click="Del(i)">×</h2>
          </div>
        </div>
      </template>
    </viewer>
    <div
      @click="$refs.fileRef.click()"
      v-show="imgList.length < maxNum"
      :class="isLoad ? 'imgLoadingDiv' : 'fileDiv'"
    >
      <p class="add" v-show="!isLoad">＋</p>
    </div>
    <!-- 多选 multiple -->
    <input
      @change="fileAfter"
      ref="fileRef"
      type="file"
      style="display: none"
    />
  </div>
</template>
<script>
import lrz from 'lrz'
import moment from 'moment'
import { authupload, yellowUpload } from 'network/upload'

export default {
  name: 'ImgUpload',
  props: {
    imgList: {
      type: Array,
    },
    maxNum: {
      type: Number,
      default: 6,
    },
    upType: {
      // 0认证 1黄页
      type: Number,
      default: 0,
    },
    parameter: {
      type: String,
      default: 'file'
    }
  },
  model: {
    prop: 'imgList',
    event: 'change',
  },
  data() {
    return {
      isLoad: false,
    }
  },
  methods: {
    // 压缩图片
    getSimg(file, name) {
      let promise = new Promise(function (resolve) {
        lrz(file, { quantity: 0.2 }).then(function (rst) {
          let thefile = new window.File([rst.file], name, {
            type: rst.file.type,
          })
          /* console.log(
            "压缩后大小：",
            (thefile.size / 1048576).toFixed(2) + "M"
          ); */
          resolve(thefile)
        })
      })

      return promise
    },
    fileAfter() {
      let formData = new FormData()
      formData.delete('file')

      let that = this
      let callback = function () {
        that.$emit('upOver')
      }
      // 获取 file 返回的值
      let fileList = Object.values(this.$refs.fileRef.files)
      if (fileList) {
        let fileListSix = []
        let count = fileList.length + this.imgList.length
        if (count > this.maxNum) {
          fileList.forEach((item, i) => {
            if (i < this.maxNum - this.imgList.length) {
              fileListSix.push(item)
            }
          })
          this.$Message.warning('图片最多上传' + this.maxNum + '张！')
        } else {
          fileListSix = fileList
        }
        let imgType = ['image/jpg', 'image/png', 'image/jpeg']
        let arrypromise = []
        fileListSix.forEach((item, index) => {
          if (imgType.includes(item.type)) {
            let oldFileName = item.name
            let nameIndex = oldFileName.indexOf('.')
            let nameExtension = oldFileName.substring(nameIndex) //图片扩展名
            let newFileName =
              moment().format('YYYYMMDDHHmmss') + index + nameExtension
            this.isLoad = true
            arrypromise.push(this.getSimg(item, newFileName))

            // 清空 ref 的值，防止上传相同图片失败
            this.$refs.fileRef.value = null
          } else {
            formData.append(this.parameter, {})
            this.$Message.warning('图片格式不正确！')
          }
        })
        Promise.all(arrypromise).then((res) => {
          res.forEach((i) => {
            formData.append(this.parameter, i)
          })
          this.uploadApi(formData, that)
        })
      }
      // console.log("imgList: ", this.imgList)
      this.$emit('change', this.imgList)
    },
    uploadApi(formData, that) {
      if (this.upType == 0) {
        authupload(formData).then((res) => {
          that.isLoad = false
          let e = res.data
          that.imgList.push(e.fileName)
        })
      } else if(this.upType == 1) {
        yellowUpload(formData).then((res) => {
          that.isLoad = false
          let e = res.data
          // console.log('黄页：',res);
          that.imgList.push(e.fileName)
        })
      }
    },
    Del(i) {
      this.imgList.splice(i, 1)
    },
  },
  computed: {
    imgUrl() {
      if (this.upType == 0) {
        return this.$config.personalCenter.authImgUrl
      } else if (this.upType == 1) {
        return this.$config.personalCenter.yellowPage
      }
    },
  },
}
</script>
<style scoped>
.imgDiv {
  width: 110px;
  height: 100px;
}
.img {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  position: absolute;
}
.delCls {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  background-color: red;
  color: white;
  position: relative;
  top: -5px;
  left: 90px;
}
.delCls h2 {
  cursor: pointer;
  margin-top: -3px;
  font-size: 20px;
}

.fileDiv {
  width: 100px;
  height: 100px;
  border: 1px dashed #dddee1;
  text-align: center;
  line-height: 100px;
  border-radius: 5px;
}
.fileDiv:hover {
  border: 1px dashed #25449a;
  cursor: pointer;
}

.imgDiv,
.fileDiv,
.imgLoadingDiv {
  float: left;
  margin-left: 0px;
  margin-top: 10px;
}
.imgLoadingDiv {
  border: 1px solid;
  width: 100px;
  height: 100px;
  margin-right: 10px;
  border: 1px solid #dddee1;
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('/webImg/imgloding.gif');
}
.add {
  font-size: 26px;
  font-weight: 700;
}
</style>