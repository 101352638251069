<template>
  <div class="navhead">
    <div class="all">
      <div class="w1400 clearfix">
        <p class="fl">您好，欢迎来到同鑫再生！服务热线：13329574939</p>
        <div class="fr clearfix">
          <ul>
            <li v-show="isLogin">
              <router-link :to="'/personalCenter'">个人中心</router-link>
            </li>
            <li v-show="!isLogin">
              <router-link :to="'/login'">加入会员</router-link>
            </li>
            <li>
              <router-link :to="'/aboutUs'">关于我们</router-link>
            </li>
            <li>
              <a href="javascript:;" @click="goYHHT">交易后台</a>
            </li>
            <li class="last">
              <router-link :to="'/appDown'">APP下载</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="clearfix w1400 top_second">
      <div class="fl logo" @click="logoClk">
        <img src="~assets/img/public/logo.png" />
        <span>同鑫再生资源综合服务平台</span>
      </div>
      <ul class="navlist">
        <li
          v-for="(todo, index) in todos"
          v-on:click="addClass(index, todo.href)"
          v-bind:class="{
            active: $route.path == todo.href || $route.path == todo.delhref,
          }"
        >
          <span v-if="index == 0"></span>
          {{ todo.text }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavHead',
  data() {
    return {
      todos: [
        { text: '首页', href: '/home' },
        { text: '行情价格', href: '/marketprice', delhref: '/marketdetail' },
        { text: '分析指导', href: '/analysis' ,delhref: '/analysisDel'},
        { text: '废钢调价', href: '/scrapprice', delhref: '/scrapdetail' },
        // { text: '同鑫黄页', href: '/usersPage', delhref: '/usersPage/detail' },
        {text: '招标拍卖', href: '/bidAuction', delhref: '/bidAuction/detail',
        },
        // { text: '废钢地图', href: '/mappage' },
      ],
    }
  },
  methods: {
    goYHHT() {
      window.open('http://180.169.11.53:9001/#/login', '_blank')
    },
    addClass: function (index, url) {
      if (this.$route.path == url) return
      this.$router.push({ path: url })
    },
    logoClk() {
      if (this.$route.name != 'home') {
        this.$router.push({ path: '/home' })
      }
    },
  },
  computed: {
    isLogin() {
      let token = this.$store.state.token
      return token && token.length > 0
    },
  },
}
</script>

<style scoped>
.navhead {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #f1f1f1;
  z-index: 99;
}
.all {
  height: 40px;
  background-color: #e6ebf5;
  font-size: 14px;
  color: #303133;
  line-height: 40px;
}
.logo {
  cursor: pointer;
}
.fl img {
  width: 70px;
  margin-top: 25px;
  float: left;
}
.fl span {
  font-family: MicrosoftYaHei;
  color: #000;
  font-size: 24px;
  float: left;
  margin-top: 40px;
}
.fr ul li {
  float: left;
}
.fr ul li a {
  padding-left: 17px;
  color: #303133;
}
.fr ul .last a::after {
  content: '';
  /*margin-left: 17px;*/
}
.fr ul li a::after {
  content: '|';
  margin-left: 17px;
}
.fr ul .last a {
  border: none;
}
.top_second {
  height: 105px;
}
.navlist {
  float: right;
  margin-top: 32px;
}
.navlist li {
  float: left;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 16px;
  width: 100px;
  height: 46px;
  padding: 0 15px;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
  color: #000;
  /*-webkit-transition: transform 0.5s linear;*/
  /*-moz-transition: transform 0.5s linear;*/
  /*-ms-transition: transform 0.5s linear;*/
  /*-o-transition: transform 0.5s linear;*/
  /*transition: transform 0.5s linear;*/
}
.navlist li a {
  text-decoration: none;
  font-size: 16px;
  color: #4c4c4c;
  display: block;
}
.navlist li span {
  width: 18px;
  height: 18px;
  margin-bottom: 2px;
  background: url('~assets/img/sypngl.png');
}
.navlist li:hover span {
  background: url('~assets/img/sypngh.png');
}
.navlist li.active span {
  background: url('~assets/img/sypngh.png');
}
.navlist li:hover {
  color: #fff;
  background: #25449a;
  border-radius: 5px;
  /*-webkit-transform: scale(1.1);*/
  /*-moz-transform: scale(1.1);*/
  /*-ms-transform: scale(1.1);*/
  /*-o-transform: scale(1.1);*/
  /*transform: scale(1.1);*/
}
.navlist li.active {
  color: #fff;
  background: #25449a;
  border-radius: 5px;
}
.navlist li:hover a {
  color: #fff;
}
.navlist li.active a {
  color: #fff;
}
.all a:hover {
  text-decoration: underline;
  color: #25449a;
}
</style>
