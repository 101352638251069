// 黄页模块
export default {
  namespaced: true, // 命名空间
  state: () => ({
    filters: {
      industry: 0,
      type: 0,
      area: 0,
      induList: ['回收型', '加工型', '贸易型', '冶炼型'],
      typeList: [
        '废旧金属',
        '废钢',
        '贵金属',
        '小金属',
        '建材',
        '不锈钢',
        '铁矿石',
        '生铁',
        '涂镀',
        '板材',
        '管材',
        '型材',
        '铁合金',
        '钢坯',
        '热轧板',
        '冷轧板',
        '带钢',
        '型材',
        '废纸',
        '废塑料',
      ],
      areaList: []
    }
  }),
  mutations: {
    setIndustry(state, payload) {
      state.filters.industry = payload
    },
    setType(state, payload) {
      state.filters.type = payload
    },
    setArea(state, payload) {
      state.filters.area = payload
    },
    setAreaList(state, payload) {
      state.filters.areaList = payload
    },
  },
  actions: {},
  getters: {}
}