<template>
  <div class="navhead">
    <div class="all">
      <div class="w1400 clearfix">
        <p class="fl">您好，欢迎来到同鑫再生！服务热线：13329574939</p>
        <div class="fr clearfix">
          <ul>
            <li>
              <router-link :to="'/login'">加入会员</router-link>
            </li>
            <li>
              <router-link :to="'/aboutUs'">关于我们</router-link>
            </li>
            <li>
              <a href="javascript:;" @click="goYHHT">交易后台</a>
            </li>
            <li class="last">
              <router-link :to="'/appDown'">APP下载</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="clearfix w1400 top_second">
      <div @click="go('/home')" class="fl cur">
        <img src="~assets/img/public/logo.png" />
        <span>同鑫再生资源综合服务平台</span>
      </div>
      <div @click="go('/login')" class="fr img_one cur">
        <img src="~assets/img/kouhao.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavHeadlogin',
  methods: {
    go(path) {
      if (this.$route.path == path) return
      this.$router.push({ path })
    },
    goYHHT() {
      if (this.$route.path == path) return
      window.open('http://180.169.11.53:9001/#/login', '_blank')
    },
  },
}
</script>

<style scoped>
.navhead {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #f1f1f1;
  z-index: 9;
}
.all {
  height: 40px;
  background-color: #e6ebf5;
  font-size: 14px;
  color: #303133;
  line-height: 40px;
}
.fl img {
  margin-top: 25px;
  float: left;
}
.fl span {
  font-family: MicrosoftYaHei;
  color: #000;
  font-size: 24px;
  float: left;
  margin-top: 40px;
}
.fr ul li {
  float: left;
}
.fr ul li a {
  padding-left: 17px;
  color: #303133;
}
.fr ul .last a::after {
  content: '';
  /*margin-left: 17px;*/
}
.fr ul li a::after {
  content: '|';
  margin-left: 17px;
}
.fr ul .last a {
  border: none;
}
.top_second {
  height: 105px;
}
.top_second img {
  width: 70px;
}
.img_one img {
  width: 300px;
  margin: 37px 73px 0 0;
}
.all a:hover {
  text-decoration: underline;
  color: #25449a;
}
.cur {
  cursor: pointer;
}
</style>
