export default {
  dateFormat(date, str) {
    if (date && date.length > 0) {
      let time = new Date(date)
      let y = time.getFullYear()
      let m = (time.getMonth() + 1).toString().padStart(2, '0')
      let d = (time.getDate()).toString().padStart(2, '0')
      let h = (time.getHours()).toString().padStart(2, '0')
      let f = (time.getMinutes()).toString().padStart(2, '0')
      let s = (time.getSeconds()).toString().padStart(2, '0')
      if (str == 'm.d h:f') {
        return `${m}.${d} ${h}:${f}`
      } else if (str == 'y/m/d') {
        return `${y}/${m}/${d}`
      } else if (str == 'y/m/d h:f') {
        return `${y}/${m}/${d} ${h}:${f}`
      } else {
        return `${y}-${m}-${d} ${h}:${f}:${s}`
      }
    }
  },
  timeCompute(time) {
    let now = +new Date()
    let t = +new Date(time)
    let differ = parseInt((now - t) / 1000 / 60 / 60)
    return differ < 24 * 7
  }
}