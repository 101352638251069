import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';

import mutations from './mutations'
import actions from './actions'
import getters from './getters'
import personalCenter from './modules/personalCenter' // 个人中心模块
import supplyMarket from './modules/supplyMarket' // 供求市场模块
import industry from './modules/industry' // 行情分析模块
import bidAuction from './modules/bidAuction' // 招标拍卖模块
import usersPage from './modules/usersPage' // 同鑫黄页模块

Vue.use(Vuex)

// userMail 单独保存到 localStorage
const localPersisted = createPersistedState({
  key: 'userMail',
  storage: window.localStorage,
  reducer: (vuexState) => {
    let localState = Object.assign({}, vuexState);
    for (let key in localState) {
      if (key !== 'userMail') {
        delete localState[key];
      }
    }
    return localState;
  }
});

// sessionLoStorage 存储所有的数据（除了单独的 userMail 的模块）
const sessionPersisted = createPersistedState({
  storage: window.sessionStorage,
  reducer: (vuexState) => {
    let sessionState = Object.assign({}, vuexState);
    for (let key in sessionState) {
      if (key === 'userMail') {
        delete sessionState[key];
      }
    }
    return sessionState;
  }
});

// 全局变量
const state = {
  token: '',
  userMail: []
}

export default new Vuex.Store(
  {
    state,
    mutations,
    actions,
    getters,
    modules: {
      personalCenter, // 个人中心模块
      supplyMarket, // 供求市场模块
      industry, // 行情分析模块
      bidAuction, // 招标拍卖模块
      usersPage, // 同鑫黄页模块
    },
    plugins: [localPersisted, sessionPersisted]
  }
)