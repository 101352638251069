export default {
  userBackendLink: 'http://180.169.11.53:9001/#/login', // 用户后台外网连接
  pubKey: "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDKfIa/pwY4hztPZQI+iL1vNTit\n" +
    "K4zxgn+1jXEIjJAZ77AJrLLTihw2RUoriObgR55jxzopnd1G9knlOMxRXB9gaRiZ\n" +
    "kazUT5JQYjUnQ15iVUAMIf0jCyt5D7bah9WkLUDWdZ2tRqgvVPOq0gIWmmfvX8iz\n" +
    "kLFGXuzmn5g8FRsLIwIDAQAB", // 登录公钥
  personalCenter: { 
    authImgUrl: 'http://172.20.67.218:3006/authimages/', // 法人身份证，营业执照图片
    yellowPage: 'http://172.20.67.110:3001/images/' // 黄页图片
  },
  supplyMarket: {
    productImg: 'http://172.20.67.119:3006/images/' // 供求市场图片
  },
  upLoadImgUrl: {
    authupload: 'http://172.20.67.218:3006/proupload/authupload', // 认证上传图片接口地址
    yellowUpload: 'http://172.20.67.110:3001/upload', // 黄页上传图片接口地址
  },
  cateImg: [ // 关注产品时的图片
    {
      name: '稀贵金属',
      img: require('../assets/img/cateImgs/xgjs.png')
    },
    {
      name: '成品钢材',
      img: require('../assets/img/cateImgs/cpgc.png')
    },
    {
      name: '再生塑料',
      img: require('../assets/img/cateImgs/zssl.png')
    },
    {
      name: '废纸',
      img: require('../assets/img/cateImgs/fz.png')
    },
    {
      name: '废钢',
      img: require('../assets/img/cateImgs/fg.png')
    },
    {
      name: '废旧金属',
      img: require('../assets/img/cateImgs/fjjs.png')
    },
    {
      name: '废不锈钢',
      img: require('../assets/img/cateImgs/fbxg.png')
    },
    {
      name: '机械设备',
      img: require('../assets/img/cateImgs/jxsb.png')
    },
    {
      name: '利用材料',
      img: require('../assets/img/cateImgs/lycl.png')
    },
  ],
  contrantQQ: [ // 官网联系的客服QQ
    '3627494867',
    '2775708949'
  ]
}