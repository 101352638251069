<template>
  <div>
    <div class="top">
      <h1>APP扫码下载</h1>
      <img src="~assets/img/supplyMarket/QRcode.png" />
    </div>
    <div class="footer">
      <span>随时随地</span>
      <span>关注行情</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QRcode',
  data() {
    return {}
  },
}
</script>

<style scoped>
.top {
  width: 350px;
  height: 290px;
  padding: 36px 0 20px;
  text-align: center;
  background: #25449a;
  border-radius: 10px 10px 0 0;
  font-family: Microsoft YaHei;
}

.top img {
  width: 150px;
  height: 150px;
}

h1 {
  font-size: 18px;
  color: #ffffff;
  font-weight: 400;
}

img {
  margin-top: 32px;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 60px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #303133;
  background-color: #d9ebff;
  border-radius: 0px 0px 10px 10px;
}

.footer span{
  padding: 0 3px;
}
</style>
