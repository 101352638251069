<template>
  <div>
    <Cascader
      :data="data"
      v-model="value"
      placeholder="省市区"
      @on-change="onChange"
      :clearable="false"
    ></Cascader>
  </div>
</template>
<script>
import { getProvince } from 'network/public'

export default {
  name: 'provinceCities',
  props: {
    hasAll: {
      type: Boolean,
      default: false,
    },
    returnID: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
    },
  },
  model: {
    prop: 'value',
    event: 'on-change',
  },
  created() {
    getProvince().then((res) => {
      let ret = [...res.data]
      if (this.hasAll) {
        ret.unshift({ id: this.returnID ? 0 : '全部', name: '全部' })
        ret.forEach((p) => {
          if (p.children) {
            p.children.unshift({ id: this.returnID ? 0 : '全部', name: '全部' })
          }
        })
      }
      this.data = ret.map((p) => {
        if (p.children) {
          return {
            value: this.returnID ? p.id : p.name,
            label: p.name,
            children: p.children.map((c) => {
              return { value: this.returnID ? c.id : c.name, label: c.name }
            }),
          }
        } else {
          return {
            value: this.returnID ? p.id : p.name,
            label: p.name,
          }
        }
      })
    })
  },
  data() {
    return {
      data: [],
    }
  },
  methods: {
    onChange(value) {
      this.$emit('on-change', value)
    },
  },
}
</script>