<template>
  <div class="all">
    <div class="title">
      <span>{{ option.title }}</span>
    </div>
    <div class="seamless-warp" @click="clickProps($event)">
      <!--<vue-seamless-scroll-->
        <!--class="scroll"-->
        <!--:data="option.list"-->
        <!--:class-option="classOption"-->
      <!--&gt;-->
        <!--<ul>-->
          <!--<li-->
            <!--v-for="(item, index) in option.list"-->
            <!--:key="index"-->
            <!--:class="{ lihover: option.isHover }"-->
          <!--&gt;-->
            <!--<span :data-obj="JSON.stringify(item)">{{-->
              <!--option.context(item)-->
            <!--}}</span>-->
          <!--</li>-->
        <!--</ul>-->
      <!--</vue-seamless-scroll>-->
      <div class="scroll">
        <ul>
          <li
            v-for="(item, index) in option.list"
            :key="index"
            :class="{ lihover: option.isHover }"
          >
            <span :data-obj="JSON.stringify(item)">{{
              option.context(item)
            }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TransactionNews',
  props: {
    option: Object,
  },
  methods: {
    clickProps(e) {
      if (this.option.isHover) {
        if (e.target.dataset.obj) {
          let item = JSON.parse(e.target.dataset.obj)
          this.$emit('onClick', item)
        }
      }
    },
  },
  computed: {
    classOption() {
      return {
        waitTime: 0,
        step: 0.5,
        hoverStop: this.option.isHover,
      }
    },
  },
}
</script>

<style scoped>
.all {
  width: 350px;
  height: 350px;
  background: #ffffff;
  border: 1px solid #e6ebf5;
  color: #000;
}
.title {
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  padding-left: 20px;
  background: #e6ebf5;
  border-left: 3px solid #25449a;
}

.scroll {
  padding-top: 10px;
  width: 100%;
  height: 300px;
  overflow: hidden;
}
ul li {
  position: relative;
  left: 30px;
  width: 90%;
  height: 30px;
  font-size: 14px;
  list-style: disc;

}

.lihover:hover {
  cursor: pointer;
  color: #25449a;
  text-decoration: underline;
}
</style>
