import axios from 'axios'
import { Message } from 'iview'
import store from '@/store/index'
import Router from '@/router/index'

export function request(config) {
  const instance = axios.create({
    baseURL: '',
    timeout: 30000
  })
  // 请求拦截
  instance.interceptors.request.use(config => {
    config.headers.token = localStorage.getItem("token")
    return config
  }, err => {
    console.log('请求错误：', err)
  })

  // 响应拦截
  instance.interceptors.response.use(res => {
    if (res.data.code == -1) {
      Message.warning('用户登录信息已过期，请重新登录')
      store.commit('setToken', '')
      Router.push({ path: '/login' })
    } else if (res.data.code != 0) {
      // Message.error(res.data.errInfo)
    }
    return res.data
  }, err => {
    console.log('接口返回错误：', err)
  })

  return instance(config)
}
