<template>
  <div class="classifyBox">
    <span>{{ title }}：</span>
    <div class="option">
      <span
        v-for="item in flist"
        :key="item.id"
        :value="item.id"
        :class="{ classify: true, check: item.id == fid }"
        @click="click(item)"
        >{{ item.name }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterItem',
  props: {
    id: String | Number,
    title: String,
    list: Array,
    hasAll: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    click(item) {
      this.$emit('onClick', item)
    },
  },
  computed: {
    fid() {
      return this.id ? this.id : 0
    },
    flist() {
      let arr = []
      let o = this.list.find((e) => e.id)
      if (o) {
        arr = this.list
      } else {
        arr = this.list.map((e, i) => {
          return {
            id: i + 1,
            name: e,
          }
        })
      }
      if (this.hasAll) {
        return [...[{ id: 0, name: '全部' }], ...arr]
      } else {
        return arr
      }
    },
  },
}
</script>

<style scoped>
.classifyBox {
  display: flex;
  font-size: 14px;
  color: #000;
}

.classifyBox > span {
  display: block;
  width: 4%;
  height: 30px;
  line-height: 30px;
}

.option {
  max-width: 96%;
  line-height: 30px;
  display: flex;
  flex-wrap: wrap;
}

.classify {
  padding: 0 10px;
  cursor: pointer;
}

.check {
  color: #fff;
  background-color: #25449A;
  border-radius: 4px;
}
</style>
