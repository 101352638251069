export default {
  // 存储token
  setToken(state, payload) {
    state.token = payload
  },
  // 添加已有用户 已读邮箱
  addHadUserMail(state, payload) {
    let userName = localStorage.getItem('userName')
    state.userMail.forEach(e => {
      if(e.userName == userName) {
        e.mail = [...e.mail, payload]
        return
      }
    });
  },
  // 添加新用户 已读邮箱
  addNewUserMail(state, payload) {
    state.userMail.push(payload)
  },
  // 删除过期的 mail
  removeHadUserMail(state, id) {
    let userName = localStorage.getItem('userName')
    let nowMail = state.userMail.find(e => e.userName == userName).mail
    nowMail.forEach((e, i) => {
      if(e.id == id) {
        nowMail.splice(i ,1)
      }
    })
  },
}