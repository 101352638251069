import ImgUpload from './common/ImgUpload'
import ProvinceCities from './common/ProvinceCities'

import LogoBar from './content/mainTabbar/LogoBar'
import NavHead from './content/mainTabbar/NavHead'
import NavHeadlogin from './content/mainTabbar/NavHeadlogin'

import QRcode from './content/publicComs/QRcode'
import ScrollBox from './content/publicComs/ScrollBox'
import FilterItem from './content/publicComs/FilterItem'
import ImgNav from './content/publicComs/ImgNav'
import Advertisement from './content/publicComs/Advertisement'
import DetailNavBar from './content/publicComs/DetailNavBar'
import ContrantQQ from './content/publicComs/ContrantQQ'

const plugin = (Vue) => {
    if (plugin.installed) {
        return
    }
    Vue.component('ImgUpload', ImgUpload) // 上传图片控件
    Vue.component('ProvinceCities', ProvinceCities) // 省市下拉

    Vue.component('LogoBar', LogoBar) // 官网页脚
    Vue.component('NavHead', NavHead) // 官网头部（含导航）
    Vue.component('NavHeadlogin', NavHeadlogin) // 官网头部（不含导航）

    Vue.component('QRcode', QRcode) // APP扫码下载
    Vue.component('ScrollBox', ScrollBox) // 滚动框
    Vue.component('FilterItem', FilterItem) // 筛选条件
    Vue.component('ImgNav', ImgNav) // 图片导航
    Vue.component('Advertisement', Advertisement) // 广告
    Vue.component('DetailNavBar', DetailNavBar) // 详情页面导航栏
    Vue.component('ContrantQQ', ContrantQQ) // 联系QQ客服

}

export default plugin
